<template>
	<div class="v2-adopt-manager el-content">
		<a-tabs v-model:activeKey="active">
			<template #tabBarExtraContent>
				<com-worker-add 
					v-if="active == 'manager'" 
					:work-type="2" 
					:is-shop="isShop" 
					@success="getWorkerList(worker.page,worker.limit)">
				</com-worker-add>
				<router-link :to="{path:isShop ==0 ?'/adopt/work_order':'/many_store/adopt/work_order'}">
					<a-button v-if="active == 'type'" 
						type="primary" 
						v-has="{action:'/adopt/work_order',plat:isShop}">
						<i class="ri-add-line"></i>
						添加操作类型
					</a-button>
				</router-link>
			</template>
		    <a-tab-pane key="manager" tab="工人管理">
				<a-table :data-source="worker.list" :pagination="false" row-key="id" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'user_id',dataIndex:'account_id'},
					{title:'姓名',dataIndex:'name'},
					{title:'电话',dataIndex:'mobile'},
					{title:'已接工单',dataIndex:'in_delivery_num'},
					{title:'已完成工单',dataIndex:'quantityorder'},
					{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]">
				   <template #status="{record}">
				   	<a-tag :color="record.status == 1 ?'#00CC66':'#999'">
				   		{{record.status == 1 ?'正常':'禁用'}}
				   	</a-tag>
				   </template>
				   <template #action="{record}">
					   <a-space>
						   <com-worker-add 
							:work-type="2" 
							:work-data="record" 
							:is-shop="isShop" 
							:custom="true" 
							@success="getWorkerList(worker.page,worker.limit)">
							<kd-button type="primary" 
								title="编辑" 
								icon="ri-edit-line" 
								v-has="{action:'wxapp_land_edit_worker',plat:isShop}">
							</kd-button>
						</com-worker-add>
							<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_land_del_worker',plat:isShop}"  @click="delWorker(record.id)"></kd-button>
					   </a-space>
				   </template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="worker.page"
						:total="worker.count"
						@showSizeChange="(p,e)=>{getWorkerList(worker.page,e)}"
						@change="(e)=>{getWorkerList(e,worker.limit)}"
					/>
				</div>
		    </a-tab-pane>
			<a-tab-pane key="type" tab="操作类型">
				<a-table :data-source="service.list" :pagination="false" row-key="id" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'操作名称',dataIndex:'title'},
					{title:'操作详情',dataIndex:'details',slots:{customRender:'details'}},
					{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]">
					<template #status="{record}">
						<a-tag :color="record.status == 1 ?'#00CC66':'#999'">
							{{record.status == 1 ?'正常':'隐藏'}}
						</a-tag>
					</template>
					<template #details="{record}">
						<div v-for="(val,ind) in record.details" :key="ind">操作项{{ind+1}}：{{ val.title }}</div>
					</template>
					<template #action="{record}">
						<a-space>
							<router-link :to="{path:isShop ==0 ?'/adopt/work_order':'/many_store/adopt/work_order',query:{id:record.id}}">
								<kd-button type="primary" 
									title="编辑" 
									icon="ri-edit-line" 
									v-has="{action:'/adopt/work_order',plat:isShop}">
								</kd-button>
							</router-link>
							<kd-button type="danger" 
								title="删除" 
								icon="ri-delete-bin-5-line" 
								v-has="{action:'wxapp_adopt_manager_del_type',plat:isShop}" 
								@click="delWorkserver(record.id)">
							</kd-button>
						</a-space>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="service.page"
						:total="service.count"
						@showSizeChange="(p,e)=>{getWorkService(service.page,e)}"
						@change="(e)=>{getWorkService(e,service.limit)}"
					/>
				</div>
			</a-tab-pane>
			
			<a-tab-pane key="record" tab="管理项">
				<a-table :data-source="info.list" :pagination="false" row-key="id" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'操作类型',dataIndex:'type',slots:{customRender:'type'}},
					{title:'操作对象',dataIndex:'object',slots:{customRender:'object'}},
					{title:'管理员',dataIndex:'workers.name'},
					{title:'工单周期',dataIndex:'inspector_cycle',slots:{customRender:'inspector_cycle'}},
					{title:'操作时间',dataIndex:'create_time'},
				]">
					<template #type="{record}">
						<template v-if="record.adopt.works">
							<div v-for="(item,index) in record.adopt.works" :key="index">{{ item.title }}</div>
						</template>
					</template>
					<template #inspector_cycle="{record}">
						<span>{{record.adopt.inspector_cycle}}天/次</span>
					</template>
					<template #object="{record}">
						<span>{{ record.adopt.name}}</span>
						<a-tag color="green">第{{ record.adopt.numbers }}期</a-tag>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="info.page"
						:total="info.count"
						@showSizeChange="(p,e)=>{getManageRecord(info.page,e)}"
						@change="(e)=>{getManageRecord(e,info.limit)}"
					/>
				</div>
			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
import comWorkerAdd from '@/components/layout/common/com-worker-add.vue'
import { reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt.js'
import landModel from '@/api/land.js'
export default{
	name:"page-adopt-manager",
	components:{
		comWorkerAdd
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			active:"manager",
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			worker:{
				list:[],
				page:1,
				limit:10
			},
			service:{
				list:[],
				page:1,
				limit:10
			},
		})
		getManageRecord(1,_d.info.limit)
		getWorkerList(1,_d.worker.limit)
		getWorkService(1,_d.service.limit)
		function getManageRecord(page,limit){
			adoptModel.getAdoptWorkOrder(page,limit,res=>_d.info = {limit,...res})
		}
		function getWorkerList(page,limit){
			landModel.getInspector(page,limit,{type:2},res=>_d.worker = {limit,...res})
		}
		function getWorkService( page,limit ){
			adoptModel.getAdoptWorkServer(page,limit,{},res=>_d.service = {limit,...res})
		}
		const delWorker = (id)=>landModel.deleteInspector(id,()=>{
			getWorkerList(_d.worker.page,_d.worker.limit)
		})
		const delWorkserver = (id)=>adoptModel.deleteAdoptWorkServer(id,()=>getWorkService(_d.service.page,_d.service.limit))
		return{
			...toRefs(_d),
			getManageRecord,
			getWorkerList,
			delWorker,
			getWorkService,
			delWorkserver
		}
	},
	
}
</script>

<style lang="scss">	
</style>
